// DEV
// export const environment = {
// 	production: false,
// 	spsEndpointUser: 'https://wwwedu.dsps.dealerconnection.com/Sps/viewUserProfile.do',
// 	spsEndpoint: 'https://wwwedu.dsps.dealerconnection.com/Sps/index.jsp',
// 	APIEndpoint: 'https://api.d01e.gcp.ford.com/pro/fleetmain/',
// 	endPoint: 'https://corpqa.sts.ford.com/' +
// 		'adfs/oauth2/authorize?response_type=token+id_token&client_id=urn:fws:pcf:native:dev&resource=urn:fws:pcf:webservice:dev&' +
// 		'redirect_uri=https://wwwdev.na.fleet.ford.com/oauth-callback',
// 	logoutURL: 'https://wwwqa.faust.idp.ford.com/logout?back=https://wwwdev.na.fleet.ford.com',
//
// 	fleetAdminService:'https://api-dev.fordpro.com/pro/fsa/admin/',
// 	finEligibility:'https://api-dev.fordpro.com/pro/fsa/fin-eligibility/',
// 	fleetKeyCode:'https://api-dev.fordpro.com/pro/fsa/keycode/',
// 	cpaLookUpService:'https://api-dev.fordpro.com/pro/fsa/cpalookup/',
// 	batchOasisService:'https://api-dev.fordpro.com/pro/fsa/batchoasis/',
// 	paymentReportsService:'https://api-dev.fordpro.com/pro/fsa/reports/',
// 	proKeyCodeService: 'https://api-dev.fordpro.com/fsa/keycode'
// };


// QA
export const environment = {
	production: false,
	spsEndpointUser: 'https://wwwedu.dsps.dealerconnection.com/Sps/viewUserProfile.do',
	spsEndpoint: 'https://wwwedu.dsps.dealerconnection.com/Sps/index.jsp',
	APIEndpoint: 'https://api.qa01e.gcp.ford.com/pro/fleetmain/',
	endPoint: 'https://corpqa.sts.ford.com/' +
	'adfs/oauth2/authorize?response_type=token+id_token&client_id=urn:fws:pcf:native:qa&resource=urn:fws:pcf:webservice:qa&' +
	'redirect_uri=https://wwwqa.na.fleet.ford.com/oauth-callback',
	logoutURL: 'https://wwwqa.faust.idp.ford.com/logout?back=https://wwwqa.na.fleet.ford.com',

	fleetAdminService:'https://api-qat.fordpro.com/pro/fsa/admin/',
	finEligibility:'https://api-qat.fordpro.com/pro/fsa/fin-eligibility/',
	fleetKeyCode:'https://api-qat.fordpro.com/pro/fsa/keycode/',
	cpaLookUpService:'https://api-qat.fordpro.com/pro/fsa/cpalookup/',
	batchOasisService:'https://api-qat.fordpro.com/pro/fsa/batchoasis/',
	paymentReportsService:'https://api-qat.fordpro.com/pro/fsa/reports/',
	proKeyCodeService: 'https://api-stg.fordpro.com/fsa/keycode'
};

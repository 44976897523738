import {Component, OnInit} from '@angular/core';
import {Message, SelectItem} from 'primeng/api';
import {BatchOasis} from '../models/BatchOasis';
import {DatePipe} from '@angular/common';
import {ErrorResponseData} from '../models/ErrorResponseData';
import {BatchOasisService} from '../services/batchOasis.service';
import { saveAs } from 'file-saver';


@Component({
	selector: 'app-batch-oasis-report',
	templateUrl: './batch-oasis-report.component.html',
	styleUrls: ['./batch-oasis-report.component.css']
})
export class BatchOasisReportComponent implements OnInit {
	reportMsgs: Message[] = [];
	fromDate: any;
	toDate: any;
	isResponse: any;
	isRep: any;
	response: SelectItem[];
	batchOasisReports: BatchOasis[];
	showReport = false;
	userId: string;
	uId: any;
	fDate: any;
	tDate: any;
	errorResponse: ErrorResponseData;
	showError: Boolean = false;
	showErrors: Boolean = false;
	errorMsg: string;
	msg: string;
	showSpinner: Boolean = false;
	fromDateMax: any;
	toDateMax: any;
	toDateMin: any;
	currentYear: any;
	first: number;
	showErrMsg: string;
	constructor(public batchOasis: BatchOasisService, public datepipe: DatePipe) {
		this.fromDateMax = new Date();
		this.toDateMax = new Date();
		this.toDateMin = new Date('01/01/1990');
		this.currentYear = new Date().getFullYear();
	}

	ngOnInit() {
		this.fromDate = new Date();
		this.toDate = new Date();
		this.loadResponseDropdown();
	}

	loadResponseDropdown() {
		this.response = [
			{label: 'All', value: null},
			{label: 'Yes', value: 'Yes'},
			{label: 'No', value: 'No'},
			{label: 'Pending', value: 'P'}
		];
	}


	submit() {
		this.showReport = false;
		this.showSpinner = true;
		this.batchOasisReports = [];
		this.showError = false;
		this.showErrors = false;
		if ((this.toDate === null || this.toDate === undefined || this.toDate === '') && (this.fromDate === null || this.fromDate === undefined  || this.fromDate === '')) {
			this.showReport = false;
			this.showSpinner = false;
			this.showError = true;
			this.showErrMsg = 'Please Select "Report From" and "Report To" ';

		} else if (this.fromDate === null || this.fromDate === undefined  || this.fromDate === '' ) {
			this.showReport = false;
			this.showSpinner = false;
			this.showError = true;
			this.showErrMsg = 'Please Select "Report From"';
		} else if (this.toDate === null || this.toDate === undefined || this.toDate === '') {
			this.showReport = false;
			this.showSpinner = false;
			this.showError = true;
			this.showErrMsg = 'Please Select "Report To"';
		} else if (new Date(this.fromDate) > new Date(this.toDate)) {
			this.showReport = false;
			this.showSpinner = false;
			this.showError = true;
			this.showErrMsg = 'Invalid Search criteria. Please select "Report From" date as lesser than "Report To" date and then proceed';
		} else {
			this.fDate = this.datepipe.transform(this.fromDate, 'MM/dd/yyyy');
			this.tDate = this.datepipe.transform(this.toDate, 'MM/dd/yyyy');
			this.uId = this.userId === undefined ? null : this.userId === '' ? null : this.userId;
			this.isRep = this.isResponse === undefined ? null : this.isResponse;
			const RecallRequestData = { 'fromDate': this.fDate, 'toDate': this.tDate, 'userid': this.uId , 'responseSent' : this.isRep};
			this.batchOasis.getBatchOasisReport(RecallRequestData).subscribe(data => {

				this.showSpinner = false;
				if (data['result'] !== null && data['result']['recallRequestDataApis'] !== null ) {
					this.batchOasisReports = data['result']['recallRequestDataApis'];
					this.showReport = true;
					this.showError = false;
					this.showErrors = false;
				} else {
					this.errorResponse = new ErrorResponseData(data);
					this.showErrors = true;
					this.showReport = false;
					if (this.errorResponse && this.errorResponse.error && this.errorResponse.error.messages) {
						if ( this.errorResponse.error.messages[0] === 'No Record Found') {
							this.errorMsg = this.errorResponse.error.messages[0];
						}
					}
				}
			});
		}

	}

	clear() {
		this.fromDate = new Date();
		this.toDate = new Date();
		this.toDateMin = null;
		this.isResponse = null;
		this.userId = null;
		this.showReport = false;
		this.showError = false;
		this.showErrors = false;
	}

	removeFromYearErrorMessage() {
		this.toDateMin =  new Date(this.fromDate);
		if (this.fromDate >  this.toDate) {
			this.toDate = null;
		}
		this.showReport = false;
		this.showError = false;
		this.showErrors = false;
		if (this.toDateMin >  this.toDateMax) {
			this.fromDate = null;
			this.toDateMin = null;
			this.toDateMax = new Date();
		}
	}

	removeReport() {
		this.showReport = false;
		this.showError = false;
		this.showErrors = false;
	}

	showErrorMsg(errorMsg) {
		if (errorMsg) {
			this.reportMsgs = [];
			this.reportMsgs.push({
				severity: 'error',
				summary: 'Error Message',
				detail: errorMsg
			});
		}
	}
    getDownloadExcel() {
		this.showSpinner = true;
		const RecallRequestData = { 'fromDate': this.fDate, 'toDate': this.tDate, 'userid': this.uId , 'responseSent' : this.isRep};
			this.batchOasis.getDownloadExcelForBatchOasis(RecallRequestData).subscribe(data => {
				this.showSpinner = false;
				if (data.size > 205 && data['result'] !== null) {
					this.downloadFileforBatch(data);
				} else {
					this.showError = true;
					this.showErrMsg = 'Error while downloading excel';
				}
				});
			}

	downloadFileforBatch(data: BlobPart) {
			const fileName = 'BatchOasisReport.xls';
			const ua: string = window.navigator.userAgent;
			const windowsBrowserRegex = /Trident|MSIE|Edge/;
			const isIE = ua.match(windowsBrowserRegex);
			const blob = new Blob([data], { type: 'application/pdf' as string });
			const a = document.createElement('a');
			a.href = URL.createObjectURL(blob);
			if (isIE) {
			saveAs(blob, fileName);
			} else {
			a.setAttribute('target', '_blank');
			a.download = fileName;
			a.click();
			}
 			a.remove();
			}

}
